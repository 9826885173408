export const STORAGE = {
    MATERIALS: 'materials',
    QUESTIONNARIES: 'questionnaires',
    JOURNALS: 'journals',
    BLOGS: 'blog',
    TESTIMONIALS: 'testimonials',
    CLINICS: 'clinics'
}

export const STORAGEURL = `gs://${process.env.REACT_APP_FB_BUCKET}`;

export const FILENAME = {
    BLOGFILENAME: 'blogs.json',
    QUESTIONNARIESFILE: 'TRQ.json',
    JOURNALSFILE: 'journals.json',
    TESTIMONIALSFILE:'testimonials.json',
    CLINICSFILENAME: 'clinic-list.json',
    SUPPORTINGMATERIALS: 'supporting_materials.json',
}