export const ROLES = {
  ADMIN: "admin",
  CLINICIANINCLINIC: "clinician (in-clinic)",
  CLINICIANINHOUSE: "clinician (in-house)",
  CLINICIANPENDING: "clinician (pending)",
  CLINICIAN: "clinician",
  CLIENT: "client",
};

export const ROLE_PRODUCTS = {
  admin: [
    { value: "purchase1", name: "Wholesale Purchase – Purchase now" },
    { value: "purchase2", name: "Wholesale Purchase – Invoice me" },
    { value: "purchase3", name: "Client Purchase – Upfront Payment" },
    { value: "purchase4", name: "Client Purchase – 6 x Monthly Installments" },
    { value: "purchase5", name: "No Fee" },
  ],
  "clinician (in-clinic)": [
    { value: "purchase1", name: "Wholesale Purchase – Purchase now" },
    { value: "purchase2", name: "Wholesale Purchase – Invoice me" },
    { value: "purchase5", name: "No Fee" },
  ],
  "clinician (in-house)": [
    { value: "purchase1", name: "Wholesale Purchase – Purchase now" },
    { value: "purchase2", name: "Wholesale Purchase – Invoice me" },
    { value: "purchase3", name: "Client Purchase – Upfront Payment" },
    { value: "purchase4", name: "Client Purchase – 6 x Monthly Installments" },
    { value: "purchase5", name: "No Fee" },
  ],
};
